

























import { Component, Mixins } from 'vue-property-decorator';

import { BlogFilter } from 'client-website-ts-library/filters';

import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { ItemCyclerEmbeddableSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import { Blog } from 'client-website-ts-library/types';
import Embeddable from '@/components/Common/Embeddable.vue';
import { EmbeddedContent } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

@Component({
  components: {
    Embeddable,
  },
})
export default class TVDisplayBlogs extends Mixins(View) {
  private dwellTime = 70 * 1000 * 1;

  private maxListings = 5;

  private isFullScreen = false;

  private nextTimeout?: number;

  private currentIdx = 0;

  private blog: EmbeddedContent | null = null;

  private filter: BlogFilter = new BlogFilter({
    Tag: 'video',
  });

  private cyclerItems: EmbeddedContent[] = [];

  fullScreen() {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  queueNext() {
    clearTimeout(this.nextTimeout);

    this.nextTimeout = setTimeout(() => {
      this.currentIdx += 1;

      if (this.currentIdx >= this.cyclerItems.length && this.cyclerItems.length > 0) {
        this.updateBlogs().then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.currentIdx = 0;
          this.blog = null;
        });
      } else {
        this.blog = null;
      }
    }, this.dwellTime);
  }

  handleAfterLeave() {
    this.blog = this.cyclerItems[this.currentIdx];
    this.queueNext();
  }

  updateBlogs() {
    return new Promise((resolve) => {
      API.Blogs.Search(this.filter).then((data) => {
        this.cyclerItems = data.filter((t) => t.EmbeddedContent !== null).map((t) => t.EmbeddedContent[0]!);
        this.handleAfterLeave();
        resolve();
      }).catch(() => {
        if (this.cyclerItems.length > 0) {
          this.handleAfterLeave();
          resolve();
        }
      });
    });
  }

  mounted() {
    this.updateBlogs();
  }
}
